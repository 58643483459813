
/*
NOTES:
	-Used in B_REST_App_base to concat this to app's custom version. Check B_REST_App_base::_v_frontend() docs
		-Core version control:
			-Frontend: in /bREST/core/version.js
			-Server:   in /bREST/bREST_base::VERSION & /bREST/version.info
		-Custom version control:
			-Frontend: in /custom/App.version.js
			-Server:   in bREST_Custom::CUSTOM_VERSION
History:
	0.1 (2021-05-13) (Flag Franchise)
	0.2 (2021-06-10) (Flag Franchise)
	0.3 (2022-05-05) (Used in Flag, made appGenerator, starting to use in SPAD)
	0.4 (2022-09-18) (Random stuff for Flag, CPA & SPAD)
	0.5 (2022-09-18) (Random stuff for Flag, CPA & SPAD)
	0.6 (2022-09-23) (Random stuff for Flag)
	0.7 (2022-09-28) (Fixes for SPAD)
	0.8 (2022-09-30) (Fixes for SPAD)
	0.9 (2022-10-05) (Picker events, show-number-up-down-arrows & :data-debug-field-name-path in BrFieldDb, for SPAD)
	1.0 (2022-10-06) (Toggling label vs shortLabel w shortLabelUntilBreakpoint in BrFieldDb, for SPAD)
	1.1 (2022-10-16) (dt_fromYmd, valToNumber, router reactivity hack (viewTransferProps), BrFieldDb clear blur fix, for SPAD)
	1.2 (2022-10-17) (routes_goBlank_x(), for SPAD)
	1.3 (2022-10-18) (Mainly documentation, for SPAD)
	1.4 (2022-10-19) (Mainly inverting of OP_NULL & OP_N_NULL when we pass falsy val, for SPAD)
	1.5 (2022-10-22) (Auto handling of sub model field filters, filters refactoring & OP_0_EMPTY_STR, for SPAD)
	1.6 (2022-10-22) (Added slots in generic list, for SPAD)
	1.7 (2022-10-22) (Added onErr_overlayDomTree, for SPAD)
	1.8 (2022-10-24) (For SPAD:
						Rem B_REST_Utils::throwEx_isBubbling
						B_REST_Model::asyncTasks_x promise
						Error box shadow for Vue global warn handler
						BrGenericFormBase awaitUnsavedChangesSaved & save logic + tmp toaster
						Overriding BrGenericListBase actions w :override-<type>-action-hook--<actionName>
					 )
	1.9 (2022-10-25) (More route info vars, auto calc of travelDir, generic form showSkeletonLoader, for SPAD)
	2.0 (2022-10-25) (Fix TYPE_DECIMAL DB field JS precision hell, generic form showValidationErrs, for SPAD)
	2.1 (2022-10-26) (Props for <template #data-table-area>, bad clear btn RO state for pickers, for SPAD)
	2.2 (2022-10-26) (Nullable bool fields as radioGroup by default, for SPAD)
	2.3 (2022-10-26) (Fix for B_REST_Model::select_isUsed() & selec_x_nonEmptyVals_x() on non-allocated sub models, expand transitions, for SPAD)
	2.4 (2022-10-27) (intParse pathVars & QSA, generic form hasTitle vs showTitle, for SPAD)
	2.5 (2022-10-27) (dt_now_Ymd_x(), subModelList & modelList sort_current(Sub)Models(), for SPAD)
	2.6 (2022-10-28) (On auth prob redirect to public page, notigs_tmp -> use BrToasterManager, removing forcing of color in filters, for SPAD)
	2.7 (2022-10-29) (Infinite loop w Vue warn handler, for SPAD)
	2.8 (2022-10-29) (Console log for when UI breakpoint changes, for SPAD)
	2.9 (2022-10-29) (New date funcs, more docs, for SPAD)
	3.0 (2022-10-30) (Error toaster fix, for SPAD)
	3.1 (2022-10-31) (File download fix, global listener typo, for SPAD)
	3.2 (2022-11-18) (BASE_URL fix, for Flag)
	3.3 (2022-11-20) (B_REST_App_base::CORE_LANGS, B_REST_Utils::object/array_setupWatchProxy(), TYPE_ARR & TYPE_MULTILINGUAL_STRING, B_REST_Model_Load_SearchOptions::multilingualStrings_whichLangs, for SPAD)
	3.4 (2022-11-20) (B_REST_Utils param assertion, TYPE_MULTILINGUAL_STRING BrFieldDb implementation, TYPE_ARR & TYPE_MULTILINGUAL_STRING proxy fix & filtering, for SPAD)
	3.5 (2022-11-29) (Docs change + rem routes_useAppGeneratorRoutesInstead, for SPAD)
	3.6 (2022-12-13) (For CPA & SPAD:
						Added VUE_APP_B_REST_DEBUG_ERRS in env files
						Added flags for npm run serve: "--host=localhost --open"
						Migrating vue.config.js into B_REST_VueConfigJsHelper.js
						Rem old generator (appGeneratorRoutes)
						Rem col name validation in B_REST_Vuetify_GenericList_Col::constructor
						Changed B_REST_App_base.CORE_LANGS to B_REST_App_base.instance.appLangs
						Added routes for 403 & 404
						B_REST_Utils:
							Added dt_daysDiff(), dt_now_daysDiff(), url_removeQSA(), url_removeQSA()
						B_REST_App_base:
							Added _abstract_routes_authDefaultRouteName(), _abstract_routes_beforeNavigationChange() & _abstract_onLangChange()
							Migrated B_REST_VueApp_base's nav hook to _routes_beforeNavigationChange() here, w better fallbacking
								+ better lang switch algo on app boot + later redirections + boot_langMismatch_notYetUsed
								+ console warns
							Keeping redirection origin info in route changes w routes_current_qsa_intendedFullPath() getter (ex for a 404)
						B_REST_RouteInfo_base:
							Minor hashTag bug
							Added toOtherLang_clone() & toOtherLang_fullPath()
						B_REST_VueApp_base:
							Added brFieldDbAttrs default props obj
							Booting app w no routes, then later feeding w _routes_updateAll_forLang(), having unsure code for Vue Router 3 vs 4
								+ tmp consts TMP_ROUTES_BOOT_LOAD_ALL_LANGS & TMP_ROUTES_ON_SWITCH_BEHAVIOR
						B_REST_VueApp_RouteDef:
							Added getRouteDefFromVueRouterObj()
						BrAuthPrompts:
							Customization props (attrsVCard, attrsMainBtn & attrsAltBtn)
							Title loc
							Transition effect for going form login -> resetPwd
						BrFieldDb:
							No longer auto adding mdi-account, mdi-pwd, mdi-email etc icons
							HC consts to define where picker & pwd eye reveal btns + icons will be (ex in the "append" slot)
							CSS bug with picker and disabled pointer events
							Added labelAbove prop w CSS fixes
							Added console warn for when picker ends up w no useful toLabel (hint)
						BrGenericFormBase:
							Added modelReady() event
							Allowing hooks (modelReady, afterLoad, beforeSave, etc) to be able to use "this"
						BrGenericListBase:
							More docs
					 )
	3.7 (2022-12-21) (Added "<toLabel>" field name path expression, for SPAD)
	3.8 (2022-12-22) (For SPAD:
						B_REST_Utils:
							Added flags_console_error_addStackTrace
							Pimped_console_x() way of outputting details & stack trace
						Uniformized @click to @click.prevent.stop mostly everywhere
						GenericList:
							Rem "listComponent" params in hooks and allowed using "this" instead
							Added isCtrlClickOrMiddleClick params to hooks
							Ren row.style.class & row.style.style options to row.style.trClass & row.style.trStyle
					 )
	3.9 (2022-12-23) (For SPAD:
						Mainly stuff to help automate genericForm needing sub modules (ex /citizens/123/animals/456)
						B_REST_App_base:
							Defining loc dicts & cache earlier for err msgs
							Added ROUTES_PATH_VARS_PK_TAG const
							Added routes_current_pathVars_x() getters
						B_REST_VueApp_base:
							Ren routerHack_genericListFormModule_last_x to routerHack_transferComponentPropsToVueRouterObj_last_x
							Refactored viewTransferProps into transferComponentProps_forRouteInfo
							Auto importing components in _routes_define_genericListFormModule(), so we don't have to make webpack eager wrappers in App.js
							Added _routes_define_genericListFormSubModule()
						B_REST_App_RouteDef_base:
							Added a type prop + merged w old type_isX() getters
							Added pathVarNames_x_pkTag props
							Added path vars validation for langUrls (ex to make sure we have :pkTag)
							Refactored viewTransferProps into transferComponentProps_forRouteInfo
						GenericForm:
							Using the new B_REST_App_base::routes_current_pathVars_x() getters to get pkTags
							Added BrGenericFormBaseSubModelList.vue
						Docs improvements
					 )
	4.0 (2022-12-27) (For SPAD:
						Mainly removing apiBaseUrl_x vars from setting auto in B_REST_Descriptor, B_REST_Model & B_REST_ModelList load & save methods
						B_REST_Descriptor:
							Rem apiBaseUrl_x vars from class, but kept for loading methods
							Rem load_pk(); now use load_one()
							Ren load_uniqueKey() for load_one_uniqueKey()
						B_REST_Model:
							Rem commonDefs_load_pk(); now use commonDefs_load_one()
							Ren commonDefs_load_uniqueKey() for commonDefs_load_one_uniqueKey()
						B_REST_ModelList:
							Changed setting apiBaseUrl_x etc props as constructor options instead of accessors
							Changed commonDefs_make() into commonDefs_make_static() & commonDefs_make_forLoading()
							Rem clone()
						B_REST_App_base:
							Changed modelLists_make() into modelLists_make_static() & modelLists_make_forLoading()
					 )
	4.1 (2023-01-08) (For SPAD:
						Mainly BrFieldFile control + changing B_REST_Model::save() into a awaitUnsavedChangesSaved() that waits for fields actions and can be paused in parallel
						Refactored B_REST_ModelFileField_Control into B_REST_FileControl
						Refactored BrFieldFile for the new B_REST_FileControl
						Dropped B_REST_Vuetify_Field_File
						B_REST_App_base:
							Splitted pendingUploads_x() x2 into pendingUploads_modelFiles/customFiles_x() x4
							Fixed pendingUploads_x()
						B_REST_VueApp_base:
							Added brFieldFileAttrs
						B_REST_Model:
							Changed save() into awaitUnsavedChangesSaved() & awaitUnsavedChangesSaved_filesOnly()
							Changed toObj() params to a single options obj + added a "filesOnly" prop
							Changed unsavedChanges_unflagAllFields() params to a single option obj + added a "filesOnly" prop
							Added saving_can(), isSaving_hostModelField(), fields_haveAsyncTasks() & fields_awaitAsyncTasks()
							Rem hasAsyncTasks() & awaitAsyncTasks()
							Changed isSaving()
						B_REST_ModelList:
							Changed toObj() params to a single options obj + added a "filesOnly" prop
							Changed unsavedChanges_unflag_all() params to a single option obj + added a "filesOnly" prop
						B_REST_ModelField_base:
							Changed toObj() params to a single options obj + added a "filesOnly" prop
							Changed unsavedChanges_unflag() params to a single option obj
							Added parentModel_isSaving(), hasAsyncTasks(), hasAsyncTasks_siblingFields() & awaitAsyncTasks()
							Fixed _abstract_unsavedChanges_has() being used as func instead of getter in some der classes
						B_REST_ModelField_File:
							Binding to B_REST_FileControl
						BrGenericFormBase:
							Like B_REST_Model, changed save() into awaitUnsavedChangesSaved() & awaitUnsavedChangesSaved_filesOnly()
						BrFieldDb:
							Added ...$props in brFieldDbAttrs()
					 )
	4.2 (2023-01-09) (Docs fix, BrGenericFormBase::shouldSavingBeEnabled() logic fix, for SPAD & CPA)
	4.3 (2023-01-09) (Docs fix, changing views location from /views/ to /routerViews/, for SPAD & CPA)
	4.4 (2023-01-09) (Lang & route change bug fix, more err details in BrGenericFormBase, for SPAD & CPA)
	4.5 (2023-01-10) (BrErrorPage40X components, for CPA)
	4.6 (2023-01-12) (Exporting more classes in bREST/core/implementations/vue/index.js, for CPA)
	4.7 (2023-01-17) (For CPA:
						Added B_REST_Utils::removeProp()
						Added B_REST_ModelField_base::onChange_hooks + onChange_hooks_x()
						Fixed missing Promise ret in B_REST_Model & BrGenericFormBase's _awaitUnsavedChangesSaved()
						BrGenericFormBase:
							Default save btn now uses a new awaitUnsavedChangesSaved_alwaysResolve()
							Added customValidator() hook + customErrors_x() methods
						BrFieldDb:
							Fixed pwd progress bar UI bugs when rounded + having progress bg hidden
							Stopped setting userTouch to true when in @input
							Hidding pwd progress bar when we clear the field
						Added BrFieldDbConfirmation
					 )
	4.8 (2023-01-18) (For CPA:
						Throwing exceptions in B_REST_Model::validation_custom_recalc_wait()
						Added unicity validation in bREST_App_base::_commonDefs_setupDescriptorHooks()
							-> Still has prob where if we have a validation prob and solve it, save btn stays disabled
					 )
	4.9 (2023-01-18) (For CPA:
						-> Mainly fixed prob arisen from v4.8, so now BrGenericFormBase::shouldSavingBeEnabled() allows saving even if we have "async" validation errs
						B_REST_Descriptor:
							In core boot, correctly receiving fields wCustomSetter prop
						Added B_REST_Model_CustomValidationErrorList and used in B_REST_Model, B_REST_ModelField_base & BrGenericFormBase
							Implied:
								renaming their validation_custom_errorMsg to validation_custom_errorList
								adding validation_isValid_fastOnly()
								adding saving_can_fastOnly() in B_REST_Model only
								also using in BrAuthPrompts & BrFieldDbConfirmation
						B_REST_App_base:
							Using model's validation_custom_errorList::x_if() func in _commonDefs_setupDescriptorHooks()
						Added a includeAsyncCustomErrors param to:
							B_REST_FileControl::validation_getErrors()
							B_REST_Model::validation_getErrors()
							B_REST_ModelField_base::validation_getErrors()
							B_REST_ModelField_base::_abstract_validation_getErrors()
						Added a isMutable check in B_REST_Model::_awaitUnsavedChangesSaved()
						Added B_REST_Model_ValidationError::getCustomValidationErrors()
						Fixed BrFieldDb::final_field_isMutable() when having blockVal wCustomSetter
						Logic fix in BrFieldFile
						Migrated core loc out to custom loc:
							app.components.BrFieldDbConfirmation.notMatching
							app.descriptors.User.recoveryEmail_notVerified
							app.descriptors.User.recoveryEmail_notUnique
							app.descriptors.User.userName_notUnique
					 )
	5.0 (2023-01-25) (Added BrGenericFormBase::todos arr, for CPA)
	5.1 (2023-02-25) (For SPAD:
						Added B_REST_Request_POST_File
						Added B_REST_Request::expectsContentType_pdf()
						Added B_REST_Response::toString()
						Added BrGenericListBase::filters_applyToRequest_qsa()
						Added feature to automatically focus 1st input in BrGenericListBaseFilters, each time we open a panel (not perfect solution)
					 )
	5.2 (2023-03-21) (For SPAD: Added visual fake "required" prop in BrFieldDb.vue)
	5.3 (2023-03-27) (For SPAD: Showing test CC numbers in BrStripePaymentIntentCreditCardIFrame.vue when in dev)
	5.4 (2023-04-12) (For SPAD: Fixed B_REST_App_base::boot_await() bug not notifying when it fails, fixed email validation regex)
	5.5 (2023-04-25) (For CPA: Fixed global click btn enabled state in BrGenericList.vue::final_globalActionInfoList())
	5.6 (2023-04-26) (For CPA: Fixed param order bug w B_REST_App_base::_abstract_routes_beforeNavigationChange())
	5.7 (2023-04-27) (For CPA: Added B_REST_Utils::dt_x_minutesDiff() & fixed rounding bug in B_REST_Utils::dt_daysDiff(), improved err msg in BrFieldDb.vue)
	5.8 (2023-05-02) (For CPA: Added B_REST_Utils::dt_x_timePartsBetween())
	5.9 (2023-05-11) (For CPA: Added B_REST_Utils::dt_x_deltaMinutes())
	6.0 (2023-05-15) (For CPA: Added B_REST_Utils::dom_is_x(), B_REST_VueApp_base::scrollTo_native() & B_REST_VueApp_base::scrollTo_withinVDialog())
	6.1 (2023-05-16) (For CPA: Added genericForm_showTodos flag in B_REST_VueApp_base & used in BrGenericFormBase.vue)
	6.2 (2023-05-23) (For CPA: Added B_REST_App_base::models_getDescriptor() & B_REST_App_base::models_field_x())
	6.3 (2023-05-31) (For CPA: Added B_REST_Utils::dt_toYmd_x())
	6.4 (2023-06-01) (For CPA: Added B_REST_Utils::dt_daysInMonth(), B_REST_Utils::dt_deltaMonths(), B_REST_Utils::dt_shift_x(), B_REST_Utils::dt_toYmdHi() & B_REST_Utils::dt_todHi_x())
	6.5 (2023-06-05) (For CPA: Improved docs, added B_REST_App_base::models_field_getEnumMembers(), B_REST_VueApp_CreateCoreMixin::t_orNULL(), right drawer exports)
	6.6 (2023-06-06) (For CPA: Added B_REST_API::HEADERS_B_REST_EXTRA_HEADERS & B_REST_Request_base::extraHeaders_x())
	6.7 (2023-06-07) (For CPA: Added B_REST_Utils::dt_now_His() & B_REST_Utils::dt_now_Hi())
	6.8 (2023-06-19) (For CPA: Added B_REST_Utils::dt_now_weekday() & B_REST_Utils::dt_toWeekday())
	6.9 (2023-06-27) (For CPA:
						Added B_REST_ModelField_DB::createStandalone_x()
						BrGenericFormBase.vue:
							Added a "extraData" prop so when using forms inline in custom <v-dialog> etc we can pass an equivalent of QSA
							Rem default "ma-4" class from around form, to help when in <v-dialog>
						Created a draft for BrFieldDbStandalone.vue (does nothing for now)
					 )
	7.0 (2023-06-28) (For CPA: Fixed B_REST_Utils::object_hasPropName() when passing key as int instead of string)
	7.1 (2023-06-28) (For CPA:
						Added B_REST_Utils.url_addQSAAndHashTag()
						Added B_REST_App_base::routes_goX_root()
						B_REST_App_RouteDef_base::toFullPath(): Added validation & used B_REST_Utils.url_addQSAAndHashTag()
						BrErrorPageX.vue: Added a btn to ret to root, and merged common code
					 )
	7.2 (2023-06-29) (For CPA: Bug fix in B_REST_App_base::_routes_beforeNavigationChange(), exposing B_REST_Vuetify_Prompt in implementations/vue/index.js)
	7.3 (2023-06-29) (For CPA:
						Added B_REST_VueApp_base::_constructor_mountVue_install_$bREST() to prevent hell w der class props that had no reactivity
						Improved interception concept in B_REST_Vuetify_Prompt & fixed loading progress bar visibility
					 )
	7.4 (2023-06-30) (For CPA:
						Added B_REST_App::models_toLabelCache_x()
						BrFieldDb.vue: Fixed picker bugs + use B_REST_App::models_toLabelCache_x() for when we set FKs vals wo preloaded lookup data
					 )
	7.5 (2023-06-30) (For CPA: Bug fix in BrFieldDb.vue w @change/input events not firing w selecting/clearing pickers)
	7.6 (2023-07-01) (For CPA:
						Fixed missing "/" in B_REST_FileControlItem::apiUrl_wDomainName()
						Added B_REST_ModelFields::ifSingle_apiUrl_wDomainName_x()
						Fixed BrGenericFormBase.vue::_awaitUnsavedChangesSaved() to only try to update URL if we had a fromRouteInfo)
					 )
	7.7 (2023-07-02) (For CPA: Fixed tooltip z-index in BrFieldDb & added $emit in BrFieldDbRecoveryEmail)
	7.8 (2023-07-02) (For CPA:
						B_REST_Utils:
							Breaking change: Ren dt_assert() to _dt_assert()
							Fixed bugs in dt_asString_isValid(), dt_assert_asString_isValid() & dt_fromYmdHis()
						BrGenericFormBase: added a cardAttrs prop
						BrFieldDb: Fixed <input type="date"> dark on dark icon
					 )
	7.9 (2023-07-03) (For CPA:
						Added B_REST_Utils::url_qsaToString()
						B_REST_Request:
							Added reConstruct() to change method & path
							Made setters for various props to force throwing, saying to use the new reConstruct()
							Changed qsa_parsed() to use B_REST_Utils::url_qsaToString()
							Added needsAccessToken_setDont() helper
						Added hook_load_before & beforeLoad to B_REST_Descriptor, B_REST_ModelList & BrGenericFormBase.vue
						BrGenericFormBase.vue:
							Added fromHooks, especially for myProfile forms
						Changed B_REST_ModelField_Other::toObj() to ret _data instead of undefined
					 )
	8.0 (2023-07-05) (For CPA:
						B_REST_App_base:
							Refactored perms_x()
							Changed where _abstract_calls_afterCall_general_handler() is called when booting vs already booted
						Throwing err in BrGenericListBase::reloadList()
					 )
	8.1 (2023-07-06) (For CPA:
						B_REST_App_base:
							Added user_isLogoutOrSudoXCallLoading, to prevent reactivity glitches
							Fixed a bug in _sharedLists_updateFromAPICall()
						BrAppBooter: Used user_isLogoutOrSudoXCallLoading, applied whole page's body bg color & fixed transition animation
						Prevented being able to sort on sub model fields in B_REST_Vuetify_GenericList_Col
					 )
	8.2 (2023-07-09) (For CPA: Throwing exception temporarily in BrGenericFormBase::_checkAutoUpdateInterval_needs() to prevent hell w setInterval)
	8.3 (2023-07-26) (For CPA: Bug fix in B_REST_App_RouteDef_base::toRouteInfo())
	8.4 (2023-07-27) (For CPA: Added B_REST_Utils::dt_x_secondsDiff())
	8.5 (2023-07-28) (For CPA:
						Improved docs related to pickers:
							B_REST_VueApp_base::pickers_getHandle()
							B_REST_VueApp_base::pickers_prompt_x()
							BrGenericListBase::picker_setup()
							BrFieldDb::_final_slotConfig_x()
							B_REST_Vuetify_pickerDef
							B_REST_Vuetify_pickerHandle
					 )
	8.6 (2023-08-11) (For CPA: Added B_REST_VueApp_base::scrollTo_top() & B_REST_VueApp_base::scrollTo_bottom())
	8.7 (2023-08-15) (For CPA: Added B_REST_Utils::CONTENT_TYPE_CSV, B_REST_Request_base::expectsContentType_csv(), B_REST_Response::data_contentType_is_csv())
	8.8 (2023-08-16) (For CPA:
						Added B_REST_App_base::server_timeZone
						B_REST_Utils:
							Ren dt_timeZone() into dt_device_timeZone()
							Added dt_server_timeZone()
							Refactored dt_now_x() to take into account server vs device timeZones
						Added BrGenericListBase::needsToolbar()
					 )
	8.9 (2023-08-20) (For CPA: Minor pwd reset loc fix)
	9.0 (2023-08-24) (For CPA: Ren B_REST_App_base::user_has() -> B_REST_App_base::user_has_avoidUsing())
	9.1 (2023-09-18) (For CPA:
						B_REST_Utils:
							Added string_removeAccents()
							Changed string_ellipsis() algo
						Added B_REST_Descriptor::calcFlatSearch_fieldNamePaths
						B_REST_FieldDescriptor_DB: Added TYPE_CALC_FLAT_SEARCH, type_is_calcFlatSearch(), create_type_calcFlatSearch()
					 )
	9.2 (2023-09-19) (For CPA: Fixed missing TYPE_CALC_FLAT_SEARCH in B_REST_Vuetify_GenericList_Filter::DB_TYPE_OP_MAP and BrFieldDb::final_ifInputOrDatePicker_type())
	9.3 (2023-09-25) (For CPA: CSS fixes in BrAuthPrompts, support for "uppercased" brFieldDbAttrs in BrFieldDb, fix to prevent webkit-autofill from adding white rectangles in inputs)
	9.4 (2023-09-26) (For CPA:
						B_REST_App_base: Added debug_authReloadErrs
						B_REST_FieldDescriptor_base:
							Added setter for isRequired, putting isNullable=false
							Added a 3rd forceIsRequired param to validation_type_errorMsg_eval()
						B_REST_ModelField_base: Added forceIsRequired accessor + used in _setVal()
						BrFieldDb: Added final_field_isRequired() & final_field_isNullable() to help implementing forceIsRequired + preventing nullable when req
						BrFieldDbConfirmation: Implementing forceIsRequired + preventing nullable when req
					 )
	9.5 (2023-10-03) (For CPA: Expose B_REST_Utils::dt_shiftDateTime_week_H_i_s())
	9.6 (2023-10-06) (For CPA:
						B_REST_DOMFilePtr: Auto calc ext (and fake name) when receiving a base64 string
						B_REST_FileControl: Added items_status_isStored_x()
						BrAppBooter: Simplified logo positionning and styling
						BrAuthPrompts: Fixed title semantics
					 )
	9.7 (2023-10-17) (For CPA: Added label prop to BrFieldDbConfirmation)
	9.8 (2023-10-19) (For CPA:
						Added B_REST_Utils::array_empty()
						Fixed B_REST_Descriptor::pkToTag_vals() handling ints as string
						Improved error msgs in B_REST_ModelField_base
						Added BrFieldDb::selectedItem_x()
					 )
	9.9  (2023-10-22) (For CPA: Reverted logic for wCustomSetter in BrFieldDb::final_field_isMutable() + throwEx fix)
	10.0 (2023-10-22) (For CPA: Rem email verification validation rule from B_REST_App_base::_commonDefs_setupDescriptorHooks(), added BrFieldDb::clearable prop)
	10.1 (2023-10-23) (For CPA: Added "parallel" option to B_REST_VueConfigJsHelper. Check its docs)
	10.2 (2023-10-26) (For Flag: Added B_REST_Utils::string_escapeRegex() & fixed B_REST_App_base::routes_getRouteInfo_fromPath() for BASE_URL)
	10.3 (2023-10-27) (For CPA:
						BrGenericFormBase: Added watches doing $emit, for model_isLoading & model_isSaving()
						BrFieldDb: Changed computed selectedItem_model() into method selectedItem_getModel()
					  )
	10.4 (2023-11-01) (For Techno: BrGenericListBaseDataTable::col_valToText() fixed code to rem tons of warnings)
	10.5 (2023-11-01) (For CPA:
						B_REST_Utils: Changed params to have oldVal vs newVal in x_setupWatchProxy() 
						B_REST_ModelField_DB:
							Added UPDATE_OLD_VAL_ON_IDX_OR_KEY_CHANGE=false
							Added oldVal, revertVal()
							Implemented oldVal vs newVal in _val_arrObjProxy_onAfterChanged()
					  )
	10.6 (2023-11-03) (For CPA:
						Added B_REST_Utils::array_areEqual() & B_REST_Utils::object_areEqual()
						B_REST_ModelList: Fixed isLoading bug + added setters
						B_REST_Model_Load_SearchOptions::modelFieldOrArr_reassign() allowed receiving NULL
						B_REST_Vuetify_GenericList_Col::constructor() removed ".modelList" typo in "this._listComponent.modelList.descriptor"
						B_REST_Vuetify_GenericList_Filter: Added op & hidden props, added updateModelListBinding(), added default val for isSet() when we have no SO
						Ren B_REST_Vuetify_PickerDef::REUSE_MODE_IF_NOT_PROMPTING for B_REST_Vuetify_PickerDef::REUSE_MODE_IF_NOT_PROMPTING
						B_REST_Vuetify_PickerHandle: Exposed bound VueComponent w getter, component_set() & component_waitMounted()
						BrGenericListBase:
							Fixed displayed paging idx becoming wrong when we close & reopen a picker
							Implemented hidden filters
							Migrated some computed in data()
							Refactored watches and lots of methods, into 1 single place (_onSpecsChanged_checkRebuildReloadList)
							Added filters_visible() & final_paging_index() computed
							Ren filters_anySet() to filters_visible_anySet() and on_paginationOrSort_change() to on_dataTablePaginationOrSort_change()
							Added filters helper filters_setVal_x(), filters_getVal_x(), filters_hide() & filters_show()
							Added param to filters_reset() to skip hidden ones
						BrFieldDb:
							Added :pickerOptions w support for apiBaseUrl_x, reloader, routeInfo, and new onPrompt_setFilters callback
							Refactored & documented _picker_emit() & _final_slotConfig_x()
					  )
	10.7 (2023-11-03) (For CPA: Ren "filter" into "uiFilter" in BrGenericListBase and other classes around)
	10.8 (2023-11-08) (For CPA: Added BrAuthPrompts::login_autoFill(), made BrFieldDbConfirmation revert userTouch from confirm when src's userTouch goes back to false, docs fix in BrFieldDb)
	10.9 (2023-11-09) (For CPA:
						Refactored timeZone logic for server & device, so server one isn't constant, and device one is always re-evaluated whenever we need it
							B_REST_Utils:
								Rem dt_diff_timeZone
								Changed dt_device_timeZone var into a getter evaluator
								Changed dt_now() logic for timeZone
								Added dt_device_isDaylightSavingTime()
							Rem B_REST_API::timeZone(), so now we pass B_REST_Utils::dt_device_timeZone() to API calls
							B_REST_App_base:
								Rem LS_KEY_REBOOT_REASON, locale_timeZone, server_timeZone
								Added _abstract_boot_await_framework_beforeAPICall() & _abstract_boot_await_framework_afterAPICall()
						Started refactoring pickerOptions logic
							Implemented B_REST_VueApp_base::_abstract_boot_await_framework_xAPICall()
							Added BrGenericXBase::isGenericXBase & B_REST_Vuetify_GenericXBase_isDerivedComponentImport()
							Added B_REST_Vuetify_PickerDef::component_isGenericList_x()
							BrGenericListBase:
								Added disableX props for global actions etc and rem mode_allows_x(), so we don't HC disabling when it's for a picker
							Started refactoring BrFieldDb picker's usage to receive vBind & vOn options and force disableX if we detect it's for a genericList, unless specified otherwise
							Added exports for B_REST_Vuetify_GenericFormBase_isDerivedComponentImport & B_REST_Vuetify_GenericListBase_isDerivedComponentImport
					  )
	11.0 (2023-11-09) (For CPA:
						Added VUE_APP_B_REST_CHECK_CODE_INTEGRITY & VUE_APP_B_REST_GENERIC_FORM_SHOW_TODOS in env files
						Added B_REST_App_base::routeDefs_get_moduleX()
						Added B_REST_VueApp_base::getVueComponentOptions()
						BrGenericXBase:
							Added mixinOptions in component exported options
							Added B_REST_Vuetify_GenericXBase_isDerivedComponentImport() & B_REST_Vuetify_GenericXBase_getMixinOptions()
						Added exports for B_REST_Vuetify_GenericXBase_getMixinOptions()
						BrGenericFormBaseSubModelList: Changed so we don't need to pass import & apiBaseUrlSub props
						IMPORTANT: Will need to change usages of <br-generic-form-base-sub-model-list /> and drop "subModelListImports"
					  )
	11.1 (2023-11-09) (For CPA:
						B_REST_App_base: Migrated setting of _instance in constructor instead of instance_init()
						B_REST_VueApp_base: Changed passed component props in _routes_define_genericListFormModule(), to have a {isCurrentRouteMainComponent:true}
						BrGenericFormBase: Added uid() & self() computed + changed fromRouteInfo prop into isCurrentRouteMainComponent
						BrGenericListBase:
							Added isCurrentRouteMainComponent & subModelListOptions props
							Rem final_fromLoader_routeInfo
							Added self() computed
							Rem support of :override-global-x-hook--y
						BrGenericFormBaseSubModelList:
							Ren "noAccordion" prop to "accordion"
							Changed how we pass props to sub list
							Added a <v-alert> for when we don't want accordion and model isNew
							Added loc mixin
							Added form prop, to not need to do $parent.$parent.$parent anymore
							Rem accordionDisabled
							Refactored so it can tell subModelList info about itself, using a new {sub_vBind:{subModelListOptions:{}}}
							Rem usage of :override-global-x-hook--y
						Added B_REST_Vuetify_GenericList_GlobalAction::defineCommonAction_add() & B_REST_Vuetify_GenericList_RowAction::defineCommonAction_edit() super helpers
						B_REST_Vuetify_PickerDef:
							Added component_ifGenericList_moduleName alternative to fetch component
					  )
	11.2 (2023-11-10) (For CPA:
						Mainly to allow pickers to have a [+] btn that works automatically. Check BrFieldDb::_picker_emit() docs
						B_REST_VueApp_base: Passing parent_x props to _routes_define_genericListFormModule() & _routes_define_genericListFormSubModule()
						Added openInVDialog option to B_REST_Vuetify_GenericList_GlobalAction::defineCommonAction_add() & B_REST_Vuetify_GenericList_RowAction::defineCommonAction_edit()
						BrGenericFormBase:
							Added closable prop w close emit
							Added parent_x props, for when it's used as a sub form of some main entity, when used in a picker, BrGenericListBase::openFormInVDialog(), etc
						Refactored BrGenericFormBaseSubModelList::checkPrepSubModelList() v-bind struct
						BrFieldDb: Removed setting disableGlobalActions=true in picker
						BrGenericListBase:
							Added formComponent to mixin, to pass BrGenericFormBase der's ()=>import()
							Added parent_x props, for when it's used as a sub form of some main entity, when used in a picker, BrGenericListBase::openFormInVDialog(), etc
							Added openFormInVDialog_x()
					  )
	11.3 (2023-11-10) (For CPA:
						Mainly for auto saving uiFilter prefs in lists
						Added B_REST_Model_Load_SearchOptions::fromObj() & B_REST_Model_Load_SearchOptions_Filter_base::fromObj()
						BrGenericListBase:
							Added uiFilters_prefs_lsKey prop
							Fixed a currentBreakpoint_reEval() bug that made options <th> to not appear in mobile
							Auto saving & loading uiFilter prefs w uiFilters_prefs_x(), when isCurrentRouteMainComponent
					  )
	11.4 (2023-11-24) (For CPA: Changed 🔓 icon in BrFieldDb)
	11.5 (2023-11-24) (For SPAD:
						Put B_REST_Vuetify_GenericListBase_createMixin's formComponent prop optional
						Stopped throwing in B_REST_App_base::notifs_x()
						Added icon option to B_REST_Vuetify_GenericList_GlobalAction::defineCommonAction_add() & B_REST_Vuetify_GenericList_RowAction::defineCommonAction_edit()
					  )
	11.6 (2023-11-25) (For SPAD: Added B_REST_VueApp_base::_routes_define_genericListFormModule_xOnly() & an extra options param to B_REST_VueApp_base::_routes_define_genericListFormModule())
	11.7 (2023-12-08) (For CPA:
						Breaking change:
							BrAppBooter:
								Put <v-app> out of the <v-slide-x-transition>, to solve a prob w BrPrompt not showing up on startup (check BrPrompt docs)
								Removed <v-main>, so layouts have to use it as root elem
								Migrated <br-app-layout-core-functionalities> contents here, so layouts must remove it
								Put error <v-banner> inside the <v-container>, to prevent hell
						BrPrompt:
							Documented BrAppBooter prob
							Put instance opt so it can be set later
					  )
	11.8 (2023-12-21) (For CPA:
						BrAppBooter: Mobile fix for when inline content overflows and break viewport, causing hell w custom LeftDrawer
						BrFieldDb:
							Removing label from computed brFieldDbAttrs, because it bypasses when final_label must ret NULL
							Added non-support of "dense" attr for upcoming refactor
					  )
	11.9 (2024-01-10) (For CPA:
						B_REST_ModelField_DB: Added data_proxy handling for TYPE_CUSTOM when we receive an arr/obj
						BrPrompt.vue: Auto splitting lines in diff <div>s when we get \n in body
					  )
	12.0 (2024-01-12) (For CPA: Prevented B_REST_FieldDescriptor_DB from throwing err when defining max prop for TYPE_JSON)
	12.1 (2024-01-15) (For CPA: BrPrompt.vue fix)
	12.2 (2024-01-16) (For CPA:
						B_REST_Utils::array_remove_byX() now ret rem item
						B_REST_VueApp_CreateCoreMixin:
							Added coreMixin_sleep_x(), coreMixin_setTimeout(), coreMixin_clearTimeout(), coreMixin_setInterval() & coreMixin_clearInterval()
							Added coreMixin_clearTimeoutsAndIntervals() called in beforeDestroy()
						B_REST_VueApp_base:
							Added VUETIFY_ANIMATION_DURATION
							Added routerHack_isCurrentRouteMainComponent_watchHack for when going from "/clients/123" to "/clients/456" for ex + pimped docs about that in multiple files
						BrGenericFormBase:
							Implemented routerHack_isCurrentRouteMainComponent_watchHack via isCurrentRouteMainComponent_watchHack
							Calling coreMixin_clearTimeoutsAndIntervals() in _fromX_setLoadModel() to prevent hell w prev record
						Using new B_REST_VueApp_CreateCoreMixin::coreMixin_x() funcs in:
							BrGenericFormBase
							BrGenericListBase & BrGenericListBaseFilters
							BrFieldDb, BrFieldDbRecoveryEmail, BrFieldFile & BrFieldFileItemPreview
							BrPicker
							BrStripePaymentIntentCreditCardIFrame
						Migrated BrStripePaymentIntentCreditCardIFrame loc
					  )
	12.3 (2024-01-19) (For CPA:
						B_REST_Utils:
							Added dt_fromYmdAndOrHis() & validation for dt_format()
							Refactored/fixed logic for DT funcs:
								dt_asString_isValid()
								dt_daysInMonth()
								dt_daysDiff()
								dt_minutesDiff()
								dt_fromYmdHis()
								dt_fromYmd()
								dt_fromHis()
								dt_device_isDaylightSavingTime()
						B_REST_FieldDescriptor_DB: Refactored logic accordingly in dtVal_toDate() & _validation_type_errorMsg_eval_dt()
						Added unit tests (TestUtils.js & implemented w dt-tests.js)
					  )
	12.4 (2024-01-19) (For CPA: Added B_REST_Utils::dt_deltaSeconds() + in unit tests)
	12.5 (2024-01-23) (For CPA: Fixed B_REST_FieldDescriptor_DB::validation_type_normalizeVal() logic for D/T, Added min/max props validation in BrFieldDb for D/T)
	12.6 (2024-01-24) (For CPA:
						B_REST_App_base:
							Set extraData=null param to login(), login_wSudoHash(), sudoIn() & sudoOut()
							Added _abstract_login_sudoX_beforeCall()
						BrAuthPrompts: Added extraData, to pass to B_REST_App_base::login()
					  )
	12.7 (2024-01-26) (For CPA:
						B_REST_Utils:
							Added localStorage_getKeys()
							Refactored localStorage_isEmpty()
							Added isPersistent mandatory param to localStorage_set()
							Added clearPersistentOnes mandatory param to localStorage_clear()
					  )
	12.8 (2024-02-09) (For CPA: Fixed BrFieldDb :error-message line height to prevent accents from being cutted)
	12.9 (2024-02-17) (For CPA: Allow loc strings to be "" or 0, but don't allow NULL)
	13.0 (2024-03-01) (For CPA:
						Added B_REST_DOMFilePtr::download_inlineNewWindow()
						B_REST_API:
							Added call_download_inlineNewWindow()
							Added {contentDispositionBaseNameWExt} to call_getObjectUrl()'s resolve
						Added B_REST_App_base::call_download_inlineNewWindow()
					  )
	13.1 (2024-03-01) (For CPA: Fixed bug in B_REST_Utils::array_setupWatchProxy())
	13.2 (2024-03-15) (For CPA:
						B_REST_Utils: Split string_toHTML() into string_toHTML_entities() & string_toHTML_pre()
						B_REST_App_base:
							Fixed ret string bug in _t_x() when we pass a lang that isn't the current one (using cache in wrong lang)
							Added t_x_debug_compareLangs()
						Missing EN loc
					  )
	13.3 (2024-03-22) (For CPA: Added B_REST_Utils::url_current_domainName() & B_REST_App_RouteInfo_base::domainNameWPort(), especially for server)
	13.4 (2024-03-22) (For Techno:
						B_REST_FieldDescriptor_DB: Allow lookups to be TYPE_ARR, so we can use them as {picker,multiple} filters in BrGenericListBase
						B_REST_Model_Load_SearchOptions_Filter_base:
							Breaking change: Ren modelFieldOrArr_reassign() to modelFieldOrArr_reAssign()
							Fixed const->let bug
							Fixed bug where passing an arr to obj_valOrArrOrNull would get discarded to NULL, when filter used to be !multiple and is not multiple
						B_REST_Vuetify_RightDrawer: Added aboveAll_permanent
						B_REST_Vuetify_GenericList_Filter: Fixed bug where fieldDescriptorOptions.lookupInfo wasn't set when multiple
						BrFieldDb:
							Optimized fetching model labels for pickers in a single API call when we have multiple <v-chip> to get their labels, w _picker_multiple_fakeItems_recalc()
							Added a PICKER_FAKE_LOADING_LABEL when fetching via _picker_multiple_fakeItems_recalc()
							Allowed TYPE_ARR to require one of "picker" or "items" prop. Used to only expect "items" to be filled.
							Use the new B_REST_Vuetify_RightDrawer::aboveAll_permanent prop when opening a picker to prevent hidden GenericListBase to lose its filters panel
								when we click anywhere in the picker's v-dialog, after having clicked on a filter w picker(BrFieldDb)
					  )
	13.5 (2024-03-29) (For CPA: Optimized B_REST_App_base::_t_x() to use strings instead of regexes for tags in replaceAll())
	13.6 (2024-04-05) (For CPA: Added B_REST_App_base::_abstract_locale_lang_fallback())
	13.7 (2024-04-09) (For CPA:
						Added B_REST_VueConfigJsHelper.cjs::outputDir so we can compile staging & prod at the same time in diff dirs
						Fixed B_REST_FieldDescriptor_DB::validation_type_errorMsg_eval()'s TYPE_MULTILINGUAL_STRING validation to take min/max into account
						BrFieldDb: Added a lookupModelName prop to pickerOptions, mainly for TYPE_ARR fields
					  )
	13.8 (2024-04-12) (For Labmat: Prevent inputting yyyyyy in D/DT fields in BrFieldDb::_final_minMax())
	13.9 (2024-04-12) (For CPA: Added B_REST_VueApp_base::autoVerifyRecoveryEmail flag to use in BrFieldDbRecoveryEmail.vue)
	14.0 (2024-04-15) (For CPA:
						B_REST_Utils:
							Fixed files_objectURL_create() to also accept String instead of just Blob/File, possibly converting to binary (ex if PDF)
						B_REST_DOMFilePtr:
							Added docs + optimized _base64DataURL_toU8Arr()
						B_REST_Response:
							Breaking changes:
								Ren debug_isDump -> debug_isDump_type
								Ren debug_replaceAppHTML() -> debug_isDump_output()
							Added data_toObjectURL() & data_toString()
						B_REST_API & B_REST_App_base: Refactor how we use B_REST_Response::debug_isDump_output()
						BrGenericFormBase: Added error handling in _fromX_setLoadModel() for when load promise rejects
					  )
	14.1 (2024-04-15) (For CPA:
						B_REST_App_base:
							Breaking changes:
								Splitted REBOOT_REASONS_TOKEN_OR_USER into REBOOT_REASONS_TOKEN & REBOOT_REASONS_USER
								Ren REBOOT_REASONS_USER_LOGOUT -> REBOOT_REASONS_LOGOUT_MANUAL
								Ren REBOOT_REASONS_DIRECTIVE_LOGOUT -> REBOOT_REASONS_LOGOUT_DIRECTIVE
							Added REBOOT_REASONS_PERMS to kick back to login/landpage etc
							Added REQUEST_QSA_REDIRECT_FULL_PATH, ex to redirect to 403's page w intended URL
							Refactored reboot() logic for the above + for perms
							Refactored _login_sudoX() usage of extraData for REQUEST_QSA_REDIRECT_FULL_PATH
						BrAuthPrompts:
							Refactored redirect logic w B_REST_App_base::REQUEST_QSA_REDIRECT_FULL_PATH
						BrErrorPageX:
							Added leaveTrackIntendedRoute
							Refactored redirect logic w B_REST_App_base::REQUEST_QSA_REDIRECT_FULL_PATH
					  )
	14.2 (2024-05-09) (For CPA:
						B_REST_Utils: Added dt_now_deltaMonths_x() & dt_now_deltaSeconds_x(), but not in unitTests/dt-tests.js yet
						BrFieldDbRecoveryEmail: Fixed main_tooltip() for when we don't want the feature
					  )
	14.3 (2024-05-17) (For CPA: Fixed B_REST_App_base::_calls_interceptCoreProps() when JSON response is NULL or [] instead of {})
	14.4 (2024-05-25) (For SPAD:
						B_REST_Model:
							Added API_CAN_DELETE_FIELDNAME & canDelete getter, so server can ret info about if we can / why we can't del records
							Added delete()
						B_REST_ModelList: Added delete()
						Added B_REST_Vuetify_GenericList_RowAction::defineCommonAction_delete()
					  )
	14.5 (2024-05-27) (For SPAD:
						B_REST_Vuetify_GenericList_Action_x: Added useCoreCommonLoc, so we don't need to bloat our custom loc for common add/edit/del actions
						BrGenericListBase: Changed action confirmation prompts to be a toaster instead of modal
					  )
	14.6 (2024-05-30) (For SPAD:
						Mainly batch actions
						Breaking changes:
							Ren delete_can_orMsg to ...delete_can_orTag everywhere
						B_REST_Descriptor:
							Added BATCH_API_CALL_QSA_PKS
							Added batchAPICall_x()
						B_REST_Model: Added commonDefs_batchAPICall_x()
						B_REST_ModelList:
							Added batchAPICall_x()
							Added deleteSome()
					  	BrGenericListBase:
							Added HELPER_BATCH_ACTION_DOWNLOADABLE_CONTENT_TYPES
							Changed $bREST.utils to B_REST_Utils & some B_REST_Utils.throwEx() to self throwEx()
							Added xActions_helper_batchAction_x()
							Added tooltips for disabled global/action buttons
						B_REST_Vuetify_GenericList_Action_base:
							Added DELETE_THROW_IF_MORE_THAN_X_MATCHES
							Added getMsg_x() & getMsg_disabledReasonTag()
							Added defineCommonAction_delete() to both B_REST_Vuetify_GenericList_GlobalAction & B_REST_Vuetify_GenericList_RowAction
							Allowed click's isEnabled to ret reason tag too, instead of just bool
						Fixed usage of B_REST_Utils::object_hasValidStruct_assert() in B_REST_FileControl, B_REST_Model, B_REST_ModelList, B_REST_ModelField_x
						Fixed a bug in B_REST_ModelField_ModelLookupRef::_abstract_toObj() always ret undefined instead of data
					  )
	14.7 (2024-05-31) (For Techno: Fixed a bug in B_REST_Vuetify_PickerHandle::_set() for BrFieldDb's picker)
	14.8 (2024-06-04) (For SPAD:
						Added core loc for:
							BrGenericListBase.globalActions.common.delete.disabledReasons.perms
							BrGenericListBase.rowActions.common.delete.disabledReasons.perms
					  )
	14.9 (2024-06-08) (For CPA:
						B_REST_Vuetify_Prompt:
							Added body_isHTML
							Added helper funcs to help automate common BrPrompt usage
						B_REST_VueApp_base: Added prompts & prompt_helper_x() funcs to help automate common BrPrompt usage
						BrAppBooter: Using the new B_REST_VueApp_base::prompts
						Improved B_REST_Vuetify_GenericList_Action_base::_loc() algo
					  )
	15.0 (2024-06-20) (For Techno: Loosened B_REST_Model::toObj()'s options param to be not req and to have default props too)
	15.1 (2024-06-25) (For CPA:
						Changed B_REST_App_base::_models_toLabelCache_get_throttleAPICall() to use server's new Model_base::toLabel() instead of ::toObj()
					  )
	15.2 (2024-06-30) (For CPA:
						Added B_REST_VueApp_CreateCoreMixin::throwEx()
						Added BrCreditCard & CC_Utils
					  )
	15.3 (2024-07-04) (For CPA:
						B_REST_VueApp_base: Not throwing anymore in _routes_define_genericListFormSubModule() when it was for a _routes_define_genericListFormModule_listOnly()
						B_REST_VueApp_CreateCoreMixin: Added coreMixin_createStandalone_x()
						CC_Utils: Splitted brand_mask() into brand_mask_progress() & brand_mask_firstLastDigits()
						BrCreditCard: Fixed responsive for CVV
					  )
	15.4 (2024-07-22) (For CPA: Fixed a bug in B_REST_FileControl::_items_validateDOMFilePtrArr() when replacing a !isMultiple file w another and triggering a max size validation err when shouldn't)
	20.0 (2024-08-20) (For CPA/ODF & SPAD white label - BusinessConfig major update:
						Added BusinessConfig to simplify code when we have multiple businesses in the same codebase:
							-A B_REST_NodeScripts_CLI in node automates localhost & build tasks + transfering files to FTPs
							-API:
								-A businessConfig.json is auto generated from B_REST_NodeScripts_CLI in app, and reflects businessTag+envTag
								-No need for:
									-routesEntrypoint.php at DOC_ROOT
									-config-x.json
									-/custom/<projName>/
								-Allows:
									-Automating maintenance mode
									-Using BusinessConfig::get() & BusinessConfig::getCustom() instead of bREST_base::x_getProp()
									-Doing env conditional checks w ex BusinessConfig::get('env_isProd') or env_isX_assert() instead of comparing SERVER_NAME
									-Auto generating /custom/data_public/_businessFiles & /custom/data_secure/<businessTag>/_businessFiles
									-Injecting userData.businessConfig in email & pdf templates automatically
									-Having diff loc per businessTag
									-Using BusinessConfig::get("coords_x") in Model_Email::_abstract_makeSenderInfo()
								-Requires:
									-Moving stuff out of /custom/<projectName>/ into just /custom/
									-/data_secure/ stuff to be as data_secure/<businessTag>/
							-App:
								-No need for:
									-/public/
								-Allows:
									-Automating creation of the /public/ dir for FTP (and localhost), containing index.html, favicons, etc
									-Automating maintenance mode
									-Using $bREST.businessConfig.x instead of process.env.X etc
									-Using $bREST.businessConfig.env_isProd for ex, for conditional stuff
									-Auto generating /_businessFiles/ instead of putting all in /public/
									-Having diff loc per businessTag
									-Changing stuff like require("@/custom/assets/logo-white.svg") into just string path like "/_businessFiles/logo-white.svg",
										if we put them in /@/businessConfig/<businessTag>/transferFiles/appPub|bothPub/
										Same in CSS:
											background-image: url(      "../assets/splashscreen--vertical--dark.jpg")
											background-image: url("/_businessFiles/splashscreen--vertical--dark.jpg")
							-Both:
								-Allows to have a JS module reused by both app & server (bPetiteVue):
									In app:
										import { Test, TestChild } from "/src/businessConfig/cpa/transferFiles/bothPub/test.module.js";
										const test = new Test();
									In bPetiteVue:
										class PDFTemplate_TestModule extends PDFTemplate_base
										{
											public function __construct()
											{
												parent::__construct();
												$this->_def_extraScripts_add_module("bob", "/_businessFiles/test.module.js");
											}
										}
										Template's script:
											const test = new window.bPetiteVue_extraModules.bob.Test();
									-> NOTE: If we had multiple businessConfig though, would prolly have to copy the .js file in each one's /transferFiles/bothPub/ though
						Added implementations/vue/nodeScripts:
							B_REST_Utils_Lite.cjs & B_REST_NodeScripts_Utils.cjs:
								Common funcs used in all of the following
							B_REST_NodeScripts_BusinessConfig.cjs:
								Core class describing all possible global & per env props
								Has funcs to convert struct (flatten) for CLI, api or app purposes, where each would expose diff props
							B_REST_NodeScripts_CLI.cjs:
								Automates lots of things:
									npm run serve:
										API - Upload maintenance.json
										API - Upload businessConfig.json
										API - Rsync - transferFiles/*
										API - Watch businessConfig
										npm run serve
										-> For one businessTag
									npm run build:
										App    - Build
										App    - Rsync - Upload to FTP
										Server - maintenance.json -> true
										App    - Bkp FTP
										Server - Upload businessConfig.json
										Server - Rsync - transferFiles/*
										Server - SVN update
										Server - maintenance.json -> false
										-> For a given envTag of multiple businessTag all at once, w progression info
									Opening localhost, staging & prod URLs in browser automatically, for wanted businessTag & envTags
									Getting core & custom version info for app & API, for all businessTags
									Outputting JSON of businessConfig intended for CLI, app or API + wanted envTag, for debugging
									Comparing all businessConfig props side to side in a table
									Eventually:
										Being able to restore a built app's FTP bkp
										Being able to replace DB & data_secure of X with Y, ex prod -> staging
							B_REST_NodeScripts_FTPTool.cjs:
								Automates transfering local files to app & API ftps, for _businessFiles, maintenance.json, businessConfig.json, etc
							vue.config.cjs:
								Automates starting localhost & building staging etc correctly, auto generating favicons, _businessFiles, index.html, managing file versions w ?v=x, etc
						B_REST_Utils:
							Fixed array_isOfObjects() logic
							Pimped object_hasValidStruct() logic & added object_hasValidStruct_one()
							Rem the algo param from hash() & pwd_raw_toFrontendHash()
							Added a isJSONWComments param to json_decode()
						B_REST_Response:
							Added HEADERS_B_REST_MAINTENANCE
							Added isMaintenance
						B_REST_App_base:
							Added REBOOT_REASONS_MAINTENANCE, isMaintenance & auto rebooting
							Added businessConfig
							Rem {version,crypto} from constructor options
							Rem {onErr_showNativeAlert,onErr_overlayDomTree,console_x} from constructor's {flag} options
							Rem {baseURL} from constructor's {api} options
							Relying on /custom/App.version.js for app version
							Rem locale_lang_fallback() & _abstract_locale_lang_fallback()
						B_REST_VueApp_base:
							Rem genericForm_showTodos & {genericForm_showTodos} from constructor {flags} options
							Fixed _routes_define_genericListFormSubModule() algo for when we just have a list wo form
						Rem B_REST_VueConfigJsHelper.cjs
						BrAppBooter: Added isMaintenance code
						Custom impacts:
							-> Whole API & app migration takes around 2h
							Rem .env.dev, .env.staging & .env.prod, then replace w a ".env":
								#WARNING: Don't add to SVN ! Only for @/bREST/core/implementations/vue/nodeScripts. Specify ex like "node -r dotenv/config someScript.js"
								SFTP_USERNAME=xxxxxx
								SFTP_PASSWORD=xxxxxx
								NEED_OPENSSL_LEGACY_PROVIDER=1/0
							Add a bREST-cli.js at root:
								const B_REST_NodeScripts_CLI = require("./src/bREST/core/implementations/vue/nodeScripts/B_REST_NodeScripts_CLI.cjs");
								const cli = new B_REST_NodeScripts_CLI();
								cli.run();
							Change vue.config.js:
								module.exports = require("./src/bREST/core/implementations/vue/nodeScripts/vue.config.cjs").generate();
							Change package.json scripts to just:
								"scripts": {
									"bREST-cli": "node -r dotenv/config bREST-cli.js"
								}
							Also add the following dependencies:
								"dotenv": "^16.4.5",
								"generate-file-webpack-plugin": "^1.0.1",
								"node-ssh": "^13.2.0",
								"prompts": "^2.4.2",
								"sharp": "^0.33.4",
								"ssh2-sftp-client": "^10.0.3",
									-> Will then need to do "npm install"
							Must add a /@/businessConfig/ dir like:
								<businessTag>/ (ex "cpa")
									config.jsonc (check @/bREST/core/implementations/vue/nodeScripts/example.config.jsonc)
									transferFiles/
										apiPriv/    -> Gets auto transfered to API/custom/data_secure/<businessTag/_businessFiles/
										apiPub/     -> Gets auto transfered to API/custom/data_public/_businessFiles/
										appPub/     -> Gets auto transfered to APP/_businessFiles/
										bothPub/    -> Gets auto transfered to the both <apiPub> & <appPub>
										favicon.png -> Gets auto resized in multiple specs for app bundle in /public/. Should be +192px
							Migrate the /public/android-chrome-192x192.png to /businessConfig/<businessTag>/transferFiles/favicon.png
							Rem the /public/ dir
							Check if should change some require("@/custom/assets/...") etc into the new _businessFiles way
							Must add a /custom/App.version.js:
								export default "<version>";
							In App.version.js & bREST_Custom docs about where version info are stored, change docs to:
								/ *
								NOTES:
									-Core version control:
										-Frontend: in /bREST/core/version.js
										-Server:   in /bREST/bREST_base::VERSION & /bREST/version.info
									-Custom version control:
										-Frontend: in /custom/App.version.js
										-Server:   in bREST_Custom::CUSTOM_VERSION
								* /
							App.js:
								Rem const debugErrs
								Rem {version,crypto} from constructor options
								Rem {onErr_showNativeAlert,onErr_overlayDomTree,console_x,genericForm_showTodos} from constructor's {flag} options
								Change {debug_authReloadErrs:debugErrs} into {debug_authReloadErrs:false}
								Rem {baseURL} from constructor's {api} options
								Rem _abstract_locale_lang_fallback()
							Custom <loc>.json:
								Rem app.booter.error
							Rem the algo param from B_REST_Utils::hash() & B_REST_Utils::pwd_raw_toFrontendHash()
							Change $bREST.locale_lang_fallback to $bREST.businessConfig.both_defaultLang
							Change refs to API public files in API/custom/<projectName>/data_public/ to /custom/data_public/
							Change ex "Cardio Plein Air" into $bREST.businessConfig.businessName
							Change things like:
								process.env.NODE_ENV==="development" and such to $bREST.businessConfig.env_isDev() etc
								Also adapt process.env.VUE_APP_x refs to equivalent
							CTRL+F adminLogins:
								Ex change:
										adminLogins: process.env.VUE_APP_PONT_ROULANT_XPRESS_DEBUG_ADMIN_LOGINS ? JSON.parse(process.env.VUE_APP_PONT_ROULANT_XPRESS_DEBUG_ADMIN_LOGINS) : null,
									into:
										debug_adminLogins: this.$bREST.businessConfig.debug_adminLogins ?? null,
						When API & app modifs done, do "npm run bREST-cli", use the serve command, then test login, uploading files, etc
						Procedure for easily tweaking staging & prod FTPs:
							Where ex:
								{{projName}}:     Ex "spad"
								{{SVN URL}}:      Ex "http://10.2.0.104/home/svn/{{projName}}-api"
								{{apiDir}}:       Ex "{{projName}}-api-staging.keybook.com" or "{{projName}}-api.keybook.com"
								{{appDir}}:       Ex "{{projName}}-app-staging.keybook.com" or "{{projName}}-app.keybook.com"
								{{apiNewTmpDir}}: Ex "new-{{projName}}-api-staging"
								{{apiOldDir}}:    Ex "old-{{projName}}-api-staging"
							Compare DBs struct for missing things (ex changes to `log_request`)
							mkdir /home/NFS/{{apiNewTmpDir}}; cd /home/NFS/{{apiNewTmpDir}}; svn checkout {{SVN URL}} .;
							cd /home/NFS/{{apiDir}}/custom/{{projName}}/data_secure/; rm -Rf emailLogs _models/Email;
								rsync -rP --stats /home/NFS/{{apiDir}}/custom/{{projName}}/data_secure/ /home/NFS/{{apiNewTmpDir}}/custom/data_secure/{{projName}}/
								chmod -R 0775 /home/NFS/{{apiNewTmpDir}}; chown -R apache:apache /home/NFS/{{apiNewTmpDir}}; chmod -R 0775 /home/NFS/{{appDir}}; chown -R apache:apache /home/NFS/{{appDir}};
							Go in <app>/src/businessConfig/{{projName}}/config.jsonc to TEMPORARILY change wanted env's "apiFTPDocRootDirName" to "{{apiNewTmpDir}}"
								bREST-cli.js staging/prod build: Do all steps
								Go in <app>/src/businessConfig/{{projName}}/config.jsonc to revert env's "apiFTPDocRootDirName" to literally "<apiDomainName>" (not a replacement tag)
							mv /home/NFS/{{apiDir}}/ /home/NFS/{{apiOldDir}}/; mv /home/NFS/{{apiNewTmpDir}}/ /home/NFS/{{apiDir}}/;
								rsync -rP --stats /home/NFS/{{apiOldDir}}/custom/{{projName}}/data_secure/ /home/NFS/{{apiDir}}/custom/data_secure/{{projName}}/
								chmod -R 0775 /home/NFS/{{apiDir}}; chown -R apache:apache /home/NFS/{{apiDir}};
							cd /home/NFS/; tar -zcvf "{{apiOldDir}}.tar.gz" {{apiOldDir}}/; rm -Rf {{apiOldDir}}/;
							Recompile staging/prod using bREST-cli to make sure
							Clear cache, test login, navigation, make sure no uploaded files were lost, test new file upload/del, etc
					  )
	20.1 (2024-08-21) (For CPA:
						BrFieldDb: Implemented light theme + forcing all fields to always know about ancestor theming
						BrAuthPrompts: Added optional loginSubmitLabel prop
					  )
	20.2 (2024-09-05) (For Labmat:
						BrFieldDb:
							Refactored autocomplete logic w new autocomplete_sort & autocomplete_acceptSimilarChars props. Check ifAutocomplete_filterAndSort() for docs
							Items not building a flatSearch prop anymore from B_REST_Model::toLabel(TO_LABEL_FLAT_SEARCH_SUFFIX)
							Fixed cursor color for light theme
					  )
	20.3 (2024-09-14) (Pimped docs for migrating to v20.0)
	20.4 (2024-09-15) (For Pont Roulant:
						Mainly adding prefixFilter prop to BrFieldFile, to help reuse a _def_fileList into dynamic purposes, instead of having to create tons of possible _def_fileList().
						Fixed a bug in BrGenericFormBase where the :loading v-progress-linear would cause DOM to shift and cause whole #fields slot contents to be del and rebuilt,
							which was detected by seeing that BrFieldFile's beforeDestroy() hook was called for no reason when saving form.
						B_REST_FileControl:
							Added validation_clearMsgs()
							Breaking changes: Changed items_parseDOMSelectionEvent_onInputFileChange() & items_parseDOMSelectionEvent_onDrop() args into options
							Added a "injectFilePrefix" option to items_parseDOMSelectionEvent_onInputFileChange() & items_parseDOMSelectionEvent_onDrop()
						BrFieldFile:
							Added prefixFilter and refactored most of code so a single B_REST_FileControl instance can be used at once w multiple BrFieldFile having diff purposes
					  )
	20.5 (2024-09-19) (For CPA: Fixed a validation bug in BrCreditCard.vue)
		*** IMPORTANT: Don't forget to change v below (to kick everyone out and -attempt to- empty cache)
*/

export default "20.5";
