<!--
	NOTES:
		-All fields are always stored as string, never as numbers
		-Can decide whether to include card holder name and/or CVV fields w the wName & wCvv props
		-From parent component, can use the following:
			-If using as a $ref:
				userTouch_toggleAllFields(touched)
				getValidationErrors()
				toObj()
			-Events: See EVENTS & "emits:" below
	-> Instead of using raw Vuetify fields, we should use BrFieldDb, to help w validation + also preserve proper theming (or use CustomBtn ?)
	-> Not sure if it's really a good thing to hold all those brands icons in ./icons/brands/*.png.. should check if it slows the app
-->
<template>
	<div class="br-credit-card">
		<v-row>
			<!-- OPTIONAL CARD UI ON THE LEFT -->
				<v-col v-if="wUI" cols="12" md="6" class="br-credit-card-ui d-none d-md-block">
					<div class="card-item" :data-validation-state="validationState" :class="{'-active':ui.isCardFlipped}">
						<div class="card-item__side -front">
							<div class="card-item__wrapper">
								<!-- Icons for chip & brand -->
								<div class="card-item__top">
									<img :src="icon_chip" class="card-item__chip" alt="Card chip image" />
									<div class="card-item__brand">
										<transition name="slide-fade-up"> <img v-if="icon_brand" :src="icon_brand" :key="brand.tag" alt="brand image" class="card-item__brandImg" /> </transition>
									</div>
								</div>
								<!-- Number w animation. Will give focus to matching field when clicked. Important to leave keys like "<loop_idx>-<loop_char>" -->
								<label ref="ui_cardNumber_masked" :for="_fields_makeUUID('cardNumber')" class="card-item__number">
									<transition-group name="slide-fade-right">
										<span v-for="(loop_char, loop_idx) in ui_cardNumber_masked" :key="`${loop_idx}-${loop_char}`" class="card-item__numberItem">{{ loop_char }}</span>
									</transition-group>
								</label>
								<!-- Name & expiration -->
								<div class="card-item__content">
									<!-- Name w animation. Will give focus to matching field when clicked. Important to leave keys like "<loop_idx>-<loop_char>" -->
									<label v-if="wName" ref="ui_cardName" :for="_fields_makeUUID('cardName')" class="card-item__info">
										<div class="card-item__holder">{{ t_alt("fields.cardName.label") }}</div>
										<div class="card-item__name">
											<transition-group name="slide-fade-right">
												<span v-for="(loop_char, loop_idx) in fields.cardName||' '" :key="`${loop_idx}-${loop_char}`" class="card-item__nameItem">{{ loop_char }}</span>
											</transition-group>
										</div>
									</label>
									<!-- Expiration w MM & YY -->
									<div ref="ui_cardExpiration" class="card-item__date">
										<!-- For when we click on whole box; will give focus to the month field -->
										<label :for="_fields_makeUUID('cardMonth')" class="card-item__dateTitle">{{ t_alt("ui.expiration") }}</label>
										<!-- Month UI; will give focus to matching field when clicked -->
										<label :for="_fields_makeUUID('cardMonth')" class="card-item__dateItem">
											<transition name="slide-fade-up">
												<span v-if="fields.cardMonth!==null" :key="fields.cardMonth">{{ fields.cardMonth }}</span> <span v-else key="2">{{ t_alt("fields.cardMonth.uiLabel") }}</span>
											</transition>
										</label>
										<span> / </span>
										<!-- Year UI; will give focus to matching field when clicked -->
										<label :for="_fields_makeUUID('cardYear')" class="card-item__dateItem">
											<transition name="slide-fade-up">
												<span v-if="fields.cardYear!==null" :key="fields.cardYear">{{ cardYear_short }}</span> <span v-else key="2">{{ t_alt("fields.cardYear.uiLabel") }}</span>
											</transition>
										</label>
									</div>
								</div>
							</div>
							<!-- Rectangle that will float over a sections of the UI when we focus on fields -->
							<div :class="{'-active':ui.focusedElement_style!==null}" :style="ui.focusedElement_style" class="card-item__focus" />
						</div>
						<!-- Flipping back of card w CVV -->
						<div v-if="wCvv" class="card-item__side -back">
							<div class="card-item__band" />
							<div class="card-item__cvv">
								<label :for="fields.cardCvv">
									<div class="card-item__cvvTitle">{{ t_alt("fields.cardCvv.label") }}</div>
									<div class="card-item__cvvBand"> <span>{{ fields.cardCvv }}</span> </div>
								</label>
								<div class="card-item__brand">
									<img v-if="icon_brand" :src="icon_brand" class="card-item__brandImg" alt="Dark bar image" />
								</div>
							</div>
						</div>
					</div>
				</v-col>
			<!-- FIELDS ON THE RIGHT -->
				<v-col cols="12" :md="wUI?6:12" class="br-credit-card-fields">
					<v-row dense>
						<v-col              cols="12"                                                   > <v-text-field v-bind="_fields_makeAttrs('cardNumber')" v-on="_fields_makeEvents('cardNumber')" /> </v-col>
						<v-col v-if="wName" cols="12"                                                   > <v-text-field v-bind="_fields_makeAttrs('cardName')"   v-on="_fields_makeEvents('cardName')"   /> </v-col>
						<v-col              cols="6"  :sm="wCvv?4:6" :md="!wCvv||wUI?4:4" :lg="wCvv?4:6"> <v-select     v-bind="_fields_makeAttrs('cardMonth')"  v-on="_fields_makeEvents('cardMonth')"  /> </v-col>
						<v-col              cols="6"  :sm="wCvv?4:6" :md="!wCvv||wUI?4:4" :lg="wCvv?4:6"> <v-select     v-bind="_fields_makeAttrs('cardYear')"   v-on="_fields_makeEvents('cardYear')"   /> </v-col>
						<v-col v-if="wCvv"  cols="12"  sm="4"        :md="wUI?4:4"        lg="4"        > <v-text-field v-bind="_fields_makeAttrs('cardCvv')"    v-on="_fields_makeEvents('cardCvv')"    /> </v-col>
							<!-- NOTE: Not sure about sizing for md, seems like it's always OK to put 3 on the same line, but before, used to be 6:4 for both exp fields and 12:4 for CVV -->
					</v-row>
				</v-col>
		</v-row>
		<!-- DIALOG TO AUTO FILL W DEBUG CARDS -->
			<v-dialog v-if="debugCards_isEnabled" :value="debugCards_dialog.show" @input="debugCards_dialog.show=false" max-width="500">
				<v-simple-table width="auto" style="user-select:none; cursor:pointer;">
					<tbody>
						<tr v-for="(loop_debugCard,loop_idx) in debugCards_all" :key="loop_idx" @click="_debugCards_use(loop_debugCard)">
							<td style="width:220px">{{ loop_debugCard.cardNumber }}</td> <td>{{ loop_debugCard.cardName }}</td>
						</tr>
					</tbody>
				</v-simple-table>
			</v-dialog>
	</div>
</template>

<script>
	
	import { B_REST_Utils } from "../../../../classes";
	import B_REST_VueApp_CreateCoreMixin from "../../B_REST_VueApp_CreateCoreMixin.js";
	import CC_Utils from "./CC_Utils.js";
	
	
	
	const COMPONENT_NAME         = "BrCreditCard";
	const CORE_ALT_BASE_LOC_PATH = `app.components.${COMPONENT_NAME}`;
	
	export const VALIDATION_STATES = {
		UNKNOWN: "unknown",
		VALID:   "valid",
		ERRORS:  "errors",
	};
	
	const EVENTS = {
		FIELD_INPUT:             "input:field",            //As {fieldName,value}. NOTE: For cardNumber, rets w spaces, and for cardYear, rets as 4 digits
		FIELD_CHANGE:            "change:field",           //As {fieldName,value}. NOTE: For cardNumber, rets w spaces, and for cardYear, rets as 4 digits
		VALIDATION_STATE_CHANGE: "change:validationState", //As <validationState>
	};
	
	const MONTHS = ["01","02","03","04","05","06","07","08","09","10","11","12"];
	const thisYear = new Date().getFullYear();
	const YEARS = []; for(let i=0;i<10;i++){YEARS.push((thisYear+i).toString());}
	
	
	
	export default {
		name: COMPONENT_NAME,
		mixins: [
			B_REST_VueApp_CreateCoreMixin({
				coreAltBaseLocPath: CORE_ALT_BASE_LOC_PATH,
			}),
		],
		props: {
			wName:      {type:Boolean,         required:true}, //Sometimes, we don't care about card holder's name. Can't be changed once created
			wCvv:       {type:Boolean,         required:true}, //Sometimes, we don't care about CVV (ex for Moneris). Can't be changed once created
			wUI:        {type:Boolean,         default:true},  //If we want a card UI on the left (when md+)
			debugCards: {type:[Boolean,Array], default:false}, //If not false, will add a btn on cardNumber to auto fill, with all example cards in "CC_Utils.BRANDS.X.ex". Then, can also prepend to that list an arr of {cardNumber,cardMonth,cardYear,cardName,cardCvv}
		},
		emits: Object.values(EVENTS),
		data()
		{
			//NOTE: All fields are always stored as string or NULL, but never as numbers
			const fields = {
				cardNumber: null,
				cardMonth:  null,
				cardYear:   null,
			};
			//Only add the following if req, to simplify algo
				if (this.wName) { fields.cardName=null; }
				if (this.wCvv)  { fields.cardCvv =null; } //NOTE: A random number not related to the card number, so it's not a checksum of number + expiration
			
			return {
				brand: CC_Utils.BRANDS.UNKNOWN, //Ptr on one of CC_Utils.BRANDS, against current cardNumber
				validationState: VALIDATION_STATES.UNKNOWN, //One of VALIDATION_STATES.x
				fields,
				canValidateOnInput: {cardNumber:false,cardMonth:false,cardYear:false,cardName:false,cardCvv:false}, //Check _fields_checkUpdateValidationState(). NOTE: We include cardName & cardCvv even though we might not need them
				ui: {
					focusedElement_style: null,
					isCardFlipped:        false,
				},
				debugCards_dialog: {show:false},
			};
		},
		watch: {
			wName() { B_REST_Utils.throwEx(`Not allowed to change wName`); },
			wCvv()  { B_REST_Utils.throwEx(`Not allowed to change wCvv`);  },
		},
		computed: {
			uid()                       { return this._uid; }, //All Vue component instances have a unique id
			icon_chip()                 { return this._icon_x("chip.png"); },
			icon_brand()                { return CC_Utils.brand_iconPath(this.brand); }, //NOTE: Rets NULL though if it was for CC_Utils.BRANDS.UNKNOWN, as we don't have an icon for it yet
			cardYear_short()            { return this.fields.cardYear?.substr(2) ?? null; },
			ui_cardNumber_masked()      { return CC_Utils.brand_mask_progress(this.brand,this.fields.cardNumber??""); }, //If brand's mask is like "#### #### #### ####" and we inputted "1234 56" so far, will yield "1234 56## #### ####"
			validationState_isUnknown() { return this.validationState===VALIDATION_STATES.UNKNOWN; },
			validationState_isValid()   { return this.validationState===VALIDATION_STATES.VALID;   },
			validationState_isErrors()  { return this.validationState===VALIDATION_STATES.ERRORS;  },
			debugCards_isEnabled()      { return !!this.debugCards; },
			debugCards_all()
			{
				if (!this.debugCards_isEnabled) { return null; }
				
				const debugCards = [];
				if (B_REST_Utils.array_is(this.debugCards) && B_REST_Utils.array_isOfObjects(this.debugCards)) { debugCards.push(...this.debugCards); }
				
				//Append all brands example card, setting exp date randomly to next year's jan
				{
					const cardMonth = MONTHS[0];
					const cardYear  = YEARS[1];
					
					for (const loop_brandTag in CC_Utils.BRANDS)
					{
						if (loop_brandTag==="UNKNOWN") { continue; }
						const loop_brand = CC_Utils.BRANDS[loop_brandTag];
						debugCards.push({cardNumber:loop_brand.ex, cardMonth,cardYear, cardName:`${loop_brandTag} ex`, cardCvv:"1234".substr(0,loop_brand.cvvLength)});
					}
				}
				
				return debugCards;
			},
		},
		methods: {
			//EXPOSED METHODS FOR PARENT COMPONENTS
				async reset()
				{
					await this._fields_fromObj({cardNumber:null,cardMonth:null,cardYear:null,cardName:null,cardCvv:null}); //NOTE: We include cardName & cardCvv even though we might not need them
					await this.coreMixin_sleep_nextFrame();
					this.userTouch_toggleAllFields(false);
				},
				//Normally, fields stay "normal" even if not filled or invalid, and become red only once we blur them. Use this to force all of them to become red even if not touched yet
				userTouch_toggleAllFields(touched)
				{
					for (const loop_fieldName in this.fields) { this._fields_userTouch_toggle(loop_fieldName,touched); }
					if (!touched)
					{
						for (const loop_fieldName in this.fields) { this.canValidateOnInput[loop_fieldName]=false; } //Check _fields_checkUpdateValidationState()
					}
					this._validationState_update();
				},
				//Rets an arr of err msgs, for fields w touches. So consider doing userTouch_toggleAllFields() first
				getValidationErrors()
				{
					const msgs = [];
					for (const loop_fieldName in this.fields) { msgs.push(...this.$refs[loop_fieldName].validations); }
					return msgs;
				},
				/*
				Rets all fields val, including cardNumber w & wo spaces, short & long year, and validation state (one of VALIDATION_STATES.x)
				As {cardNumber, cardMonth, cardYear, cardNumber_spaceless, cardYear_short, validationState, isValid}
				Fields are all always either strings or NULL, but never numbers
				*/
				toObj()
				{
					return {
						...this.fields,
						cardNumber_spaceless: this.fields.cardNumber ? CC_Utils.cleanup(this.fields.cardNumber) : null,
						cardYear_short:       this.cardYear_short,
						validationState:      this.validationState,
						isValid:              this.validationState_isValid,
					};
				},
			//PRIVATE METHODS
				_icon_x(relPath)
				{
					const path = require(`@/bREST/core/implementations/vue/vuetifyComponents/creditCard/icons/${relPath}`);
					return path.default || path;
				},
				_fields_makeUUID(fieldName) { return `br-credit-card-${this.uid}-${fieldName}`; },
				_fields_makeAttrs(fieldName)
				{
					const label = this.t_alt(`fields.${fieldName}.label`);
					const attrs = {
						id:             this._fields_makeUUID(fieldName),
						ref:            fieldName, //To calc validationState
						value:          this.fields[fieldName], //Req to have one way binding
						validateOnBlur: !this.canValidateOnInput[fieldName],
						outlined:       true, //NOTE: We should use BrFieldDb instead, so they respect the project's theming
						rounded:        true, //NOTE: We should use BrFieldDb instead, so they respect the project's theming
						label,
						rules: [
							(val) => (val??"")!=="" || this.t_alt(`fields.${fieldName}.rules.req`), //Convert "" & NULL into "", so it's easier to test for empty field
						],
					}
					
					switch (fieldName)
					{
						case "cardNumber":
							if (this.debugCards_isEnabled) { attrs.appendIcon="mdi-auto-fix"; }
							attrs.rules.push((val) =>
							{
								if (!CC_Utils.brand_validate(this.brand,val)) { return this.t_alt("fields.cardNumber.rules.format");   }
								if (!CC_Utils.validateLuhn(val))              { return this.t_alt("fields.cardNumber.rules.checksum"); }
								return true;
							});
						break;
						case "cardCvv":   attrs.rules.push((val) => CC_Utils.validateCVV(val)||this.t_alt("fields.cardCvv.rules.format")); break;
						case "cardMonth": attrs.items=MONTHS; break;
						case "cardYear":  attrs.items=YEARS;  break;
					}
					
					return attrs;
				},
				_fields_makeEvents(fieldName)
				{
					const events = {
						blur:     ()      => this._ui_checkUpdateFocusedArea(fieldName,false),
						focus:    async() => { await this.coreMixin_sleep_nextFrame(0); this._ui_checkUpdateFocusedArea(fieldName,true); }, //IMPORTANT: Must sleep, otherwise A's blur sometimes happen after B's focus, so we end up w no selection
						keypress: (event) => this._fields_onKeyPressOrPaste(fieldName,event),
						paste:    (event) => this._fields_onKeyPressOrPaste(fieldName,event),
						input:    (val)   => this._fields_onInput(fieldName,val),
						change:   (val)   => this._fields_onChange(fieldName),
					};
					
					switch (fieldName)
					{
						case "cardNumber":
							if (this.debugCards_isEnabled) { events["click:append"]=()=>this.debugCards_dialog.show=true; }
						break;
					}
					
					return events;
				},
					_fields_onKeyPressOrPaste(fieldName,event)
					{
						event = event || window.event;
						event.preventDefault();
						const target_andDocumentActiveElement = event.target; //NOTE: Should equal to document.activeElement
						
						let insertion = null;
						switch (event.type)
						{
							case "keypress": insertion = event.key;                                                   break;
							case "paste":    insertion = (event.clipboardData||window.clipboardData).getData("text"); break;
							default: B_REST_Utils.throwEx(`Got unexpected event type "${event.type}"`);
						}
						
						const currentVal   = this.fields[fieldName] ?? "";
						const cursor_left  = currentVal.substr(0,target_andDocumentActiveElement.selectionStart);
						const cursor_right = currentVal.substr(target_andDocumentActiveElement.selectionEnd);
						this._fields_onInput(fieldName, `${cursor_left}${insertion.toString()}${cursor_right}`);
					},
					_fields_onInput(fieldName, val)
					{
						val = val?.toString() ?? "";
						
						const oldVal = this.fields[fieldName];
						switch (fieldName)
						{
							case "cardNumber": this._fields_onInput_cardNumber(val);                      break;
							case "cardName":   this.fields.cardName  = val===""?null:val;                 break;
							case "cardMonth":  this.fields.cardMonth = val===""?null:val.padStart(2,"0"); break;
							case "cardYear":   this.fields.cardYear  = val===""?null:val;                 break; //NOTE: Here, should actually check that we get 4 digits but...
							case "cardCvv":    this._fields_onInput_cardCvv(val);                         break;
							default: B_REST_Utils.throwEx(`Unexpected field name "${fieldName}"`);
						}
						const newVal = this.fields[fieldName]; //NOTE: Because ex card number gets formatted too
						if (newVal!==oldVal)
						{
							this._fields_checkUpdateValidationState(fieldName,"input"); //Check to maybe update validation state. NOTE: Async, but we don't care
							this.$emit(EVENTS.FIELD_INPUT,{fieldName,value:newVal});
						}
					},
						_fields_onInput_cardNumber(val) //Always a string
						{
							const brand                = CC_Utils.brand_eval(val);         //Always rets one of CC_Utils.BRANDS.X, and CC_Utils.BRANDS.UNKNOWN if no match
							const cardNumber_formatted = CC_Utils.brand_format(brand,val); //Always ret a string. NOTE: If ever we debug that finalVal is OK but DOM doesn't get updated, add an "await this.coreMixin_sleep_nextFrame();" here
							
							this.brand             = brand;
							this.fields.cardNumber = cardNumber_formatted==="" ? null : cardNumber_formatted;
						},
						_fields_onInput_cardCvv(val) //Always a string
						{
							const spacelessVal = CC_Utils.cleanup(val); //Strip spaces & other shits
							this.fields.cardCvv = spacelessVal==="" ? null : spacelessVal.substr(0,this.brand.cvvLength);
						},
					_fields_onChange(fieldName)
					{
						this._fields_checkUpdateValidationState(fieldName,"change"); //Check to maybe update validation state. NOTE: Async, but we don't care
						this.$emit(EVENTS.FIELD_CHANGE, {fieldName,value:this.fields[fieldName]});
					},
						/*
						Especially for cardNumber, we don't want validation to happen as we fill the card, as it'll never be OK until we're done.
						Once we're done once, we can then validate on input
						*/
						async _fields_checkUpdateValidationState(fieldName, eventName) //input|change
						{
							const event_isOnInput = eventName==="input"
							
							if (this.canValidateOnInput[fieldName]===event_isOnInput)
							{
								if (event_isOnInput) { await this.coreMixin_sleep_nextFrame();  } //Req, as even though we updated the field's data here, it won't get propagated in the child component until Vue's nextTick() - but seems ok for @change though. Wouldn't need to do so if we used standalone B_REST_ModelField_DB
								else                 { this.canValidateOnInput[fieldName]=true; } //Meaning, once we get a @change, it's ok to validate in next @input events. NOTE: Val also updated in userTouch_toggleAllFields(false)
								
								this._fields_userTouch_toggle(fieldName, true);
								this._validationState_update();
							}
						},
					_fields_userTouch_toggle(fieldName, touched)
					{
						const fieldRef = this.$refs[fieldName];
						/*
						NOTE: Used to have the following line of code here, but don't remember why. Caused probs w validation:
							Card would turn red as soon as we started inputing cardNumber
							Then when bluring cardNumber, would turn blue
							Then when entering CVV would turn red again
							Then would have to clear CVV and refill to have it become green
								if (fieldRef.hasFocused===touched) { return; }
							Not sure if it's related to using reset() and such methods from outside, or just reusing component wo :key
						*/
						fieldRef[touched?"validate":"resetValidation"]();
						fieldRef.hasFocused = touched; //NOTE: Don't know why, but sometimes Vuetify updates it, sometimes not, so do manually
					},
					async _fields_fromObj(obj)
					{
						for (const loop_fieldName in this.fields)
						{
							this._fields_onInput(loop_fieldName,obj[loop_fieldName]);
							await this.coreMixin_sleep_nextFrame(); this._fields_onChange(loop_fieldName); //Must wait for Vue's nextTick() then call on change, or field's canValidateOnInput could still be false and prevent validation from actually happening
						}
					},
				_validationState_update()
				{
					let counts = {neverTouched:0, valid:0, invalid:0};
					for (const loop_fieldName in this.fields)
					{
						const loop_fieldRef = this.$refs[loop_fieldName];
						if      (loop_fieldRef.valid)      { counts.valid++;        }
						else if (loop_fieldRef.hasFocused) { counts.invalid++;      }
						else                               { counts.neverTouched++; }
					}
					const validationState = counts.invalid>0 ? VALIDATION_STATES.ERRORS : (counts.neverTouched===0?VALIDATION_STATES.VALID:VALIDATION_STATES.UNKNOWN);
					
					if (validationState!==this.validationState)
					{
						this.validationState = validationState;
						this.$emit(EVENTS.VALIDATION_STATE_CHANGE,this.validationState);
					}
				},
				_ui_checkUpdateFocusedArea(fieldName,isFocused)
				{
					if (!this.wUI) { return; }
					
					this.ui.isCardFlipped = fieldName==='cardCvv'&&isFocused;
					
					let ui_fieldRef = null;
					switch (isFocused?fieldName:null)
					{
						case "cardNumber": ui_fieldRef="ui_cardNumber_masked"; break;
						case "cardName":   ui_fieldRef="ui_cardName";          break;
						case "cardMonth":  ui_fieldRef="ui_cardExpiration";    break;
						case "cardYear":   ui_fieldRef="ui_cardExpiration";    break;
						case "cardCvv":    ui_fieldRef=null;                   break;
						case null:         ui_fieldRef=null;                   break;
					}
					if (ui_fieldRef)
					{
						const ui_dom = this.$refs[ui_fieldRef];
						
						this.ui.focusedElement_style = {
							width:     `${ui_dom.offsetWidth}px`,
							height:    `${ui_dom.offsetHeight}px`,
							transform: `translateX(${ui_dom.offsetLeft}px) translateY(${ui_dom.offsetTop}px)`,
						};
					}
					else { this.ui.focusedElement_style=null; }
				},
				_debugCards_use(debugCard)
				{
					this._fields_fromObj(debugCard); //NOTE: Async, but we don't care
					this.debugCards_dialog.show = false;
				},
		},
	};
	
</script>

<style scoped>

	.br-credit-card {}
		.br-credit-card-ui {}
		.br-credit-card-fields {}
			
			.card-item { max-width:430px; height:270px; margin-left:auto; margin-right:auto; position:relative; z-index:2; width:100%; }
				.card-item * { box-sizing: border-box; }
					.card-item :focus { outline:0; }
				.card-item__focus {
					position:absolute; z-index:3; border-radius:5px; left:0; top:0; width:100%; height:100%; transition:all .35s cubic-bezier(.71,.03,.56,.85); opacity:0; pointer-events:none; overflow:hidden; border:2px solid rgba(255,255,255,.65); }
					.card-item__focus:after { content:""; position:absolute; top:0; left:0; width:100%; background:#08142f; height:100%; border-radius:5px; filter:blur(25px); opacity:.5; }
					.card-item__focus.-active { opacity:1; }
				.card-item__side { border-radius:15px; overflow:hidden; box-shadow:0 20px 60px 0 rgba(14,42,90,.55); transform:perspective(2000px) rotateY(0) rotateX(0) rotate(0); transform-style:preserve-3d; transition:all .5s cubic-bezier(.71,.03,.56,.85); -webkit-backface-visibility:hidden; backface-visibility:hidden; height:100%; }
					.card-item[data-validation-state="unknown"] .card-item__side { background-image:linear-gradient(147deg,#354fce 0,#0c296b 74%); }
					.card-item[data-validation-state="valid"]   .card-item__side { background-image:linear-gradient(147deg,#4caf50 0,#17581a 74%); }
					.card-item[data-validation-state="errors"]  .card-item__side { background-image:linear-gradient(147deg,#e91e63 0,#710328 74%); }
					.card-item__side.-front { }
						.card-item.-active .card-item__side.-front { transform:perspective(1000px) rotateY(180deg) rotateX(0) rotateZ(0); }
					.card-item__side.-back { position:absolute; top:0; left:0; width:100%; transform:perspective(2000px) rotateY(-180deg) rotateX(0) rotate(0); z-index:2; padding:0; height:100%; }
						.card-item.-active .card-item__side.-back { transform:perspective(1000px) rotateY(0) rotateX(0) rotateZ(0); }
				
				.card-item__top { display:flex; align-items:flex-start; justify-content:space-between; margin-bottom:40px; padding:0 10px; }
				.card-item__chip{width:60px;}
				.card-item__brand { height:45px; position:relative; display:flex; justify-content:flex-end; max-width:100px; margin-left:auto; width:100%; }
					.card-item__brandImg { max-width:100%; -o-object-fit:contain; object-fit:contain; max-height:100%; -o-object-position:top right; object-position:top right; }
				.card-item__info { color:#fff; width:100%; max-width:calc(100% - 85px); padding:10px 15px; font-weight:500; display:block; cursor:pointer; }
				.card-item__holder { opacity:.7; font-size:13px; margin-bottom:6px; }
				.card-item__wrapper { font-family:"Source Code Pro",monospace; padding:25px 15px; position:relative; z-index:4; height:100%; text-shadow:7px 6px 10px rgba(14,42,90,.8); -webkit-user-select:none; -moz-user-select:none; -ms-user-select:none; user-select:none; }
				.card-item__name { font-size:18px; line-height:1; white-space:nowrap; overflow:hidden; text-transform:uppercase; }
					.card-item__nameItem { display:inline-block; min-width:8px; position:relative; }
				.card-item__number { font-weight:500; line-height:1; color:#fff; font-size:27px; margin-bottom:25px; display:inline-block; padding:10px 15px; cursor:pointer; }
					.card-item__numberItem { width:15px; display:inline-block; }
				.card-item__content { color:#fff; display:flex; align-items:flex-start; }
				.card-item__date { flex-wrap:wrap; font-size:18px; margin-left:auto; padding:10px; display:inline-flex; width:80px; white-space:nowrap; flex-shrink:0; cursor:pointer; }
					.card-item__date label { cursor:pointer; }
				.card-item__dateItem { position:relative; }
					.card-item__dateItem span { width:22px; display:inline-block; }
				.card-item__dateTitle { opacity:.7; font-size:13px; padding-bottom:6px; width:100%; }
				.card-item__band { background:rgba(0,0,19,.8); width:100%; height:50px; margin-top:30px; position:relative; z-index:2; }
				.card-item__cvv { text-align:right; position:relative; z-index:2; padding:15px; }
					.card-item__cvv .card-item__brand { opacity:.7; }
					.card-item__cvvTitle { padding-right:10px; font-size:15px; font-weight:500; color:#fff; margin-bottom:5px; }
					.card-item__cvvBand { height:45px; background:#fff; margin-bottom:30px; text-align:right; display:flex; align-items:center; justify-content:flex-end; padding-right:10px; color:#1a3b5d; font-size:18px; border-radius:4px; box-shadow:0 10px 20px -7px rgba(32,56,117,.35); }
			
			.card-list { margin-bottom:-130px; }
			
			.slide-fade-up-enter-active { transition:all .25s ease-in-out; transition-delay:.1s; position:relative; }
			.slide-fade-up-leave-active { transition:all .25s ease-in-out; position:absolute; }
			.slide-fade-up-enter        { opacity:0; transform:translateY(15px); pointer-events:none; }
			.slide-fade-up-leave-to     { opacity:0; transform:translateY(-15px); pointer-events:none; }
			
			.slide-fade-right-enter-active { transition:all .25s ease-in-out; transition-delay:.1s; position:relative; }
			.slide-fade-right-leave-active { transition:all .25s ease-in-out; position:absolute; }
			.slide-fade-right-enter        { opacity:0; transform:translateX(10px) rotate(45deg); pointer-events:none; }
			.slide-fade-right-leave-to     { opacity:0; transform:translateX(-10px) rotate(45deg); pointer-events:none; }
			
			.v-text-field:deep(input::-webkit-outer-spin-button) { -webkit-appearance:none; margin:0; }
			.v-text-field:deep(input::-webkit-inner-spin-button) { -webkit-appearance:none; margin:0; }
			.v-text-field:deep(input[type=number])              { -moz-appearance:textfield;          }
			
			@media screen and (max-width:480px)
			{
				.card-item { height:220px;max-width:310px;width:90%; }
					.card-item__top { margin-bottom:25px; }
					.card-item__chip { width:50px; }
					.card-item__brand { height:40px;max-width:90px; }
					.card-item__info { padding:10px; }
					.card-item__holder { font-size:12px;margin-bottom:5px; }
					.card-item__wrapper { padding:20px 10px; }
					.card-item__name { font-size:16px; }
					.card-item__number { font-size:21px;margin-bottom:15px;padding:10px 10px; }
						.card-item__numberItem { width:13px; }
							.card-item__numberItem.-active { width:16px; }
					.card-item__date { font-size:16px; }
						.card-item__dateTitle { font-size:12px;padding-bottom:5px; }
					.card-item__band { margin-top:20px; }
					.card-item__cvvBand { height:40px;margin-bottom:20px; }
				.card-list { margin-bottom:-120px; }
			}
			@media screen and (max-width:360px)
			{
				.card-item { height:180px; }
					.card-item__top { margin-bottom:15px; }
					.card-item__chip { width:40px; }
					.card-item__brand { height:30px; }
					.card-item__number { font-size:19px;margin-bottom:10px;padding:10px 10px; }
					.card-item__numberItem { width:12px; }
						.card-item__numberItem.-active { width:8px; }
					.card-item__band { height:40px;margin-top:10px; }
					.card-item__cvv { padding:10px 15px; }
						.card-item__cvvBand { margin-bottom:15px; }
			}
	
</style>